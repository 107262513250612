<template>
  <div class="w-full h-screen">
    <SiteNav />

    <easiLoading v-if="loading" />
    <SiteFooter />
  </div>
</template>

<script>
import SiteNav from "../components/SiteNav.vue";
import SiteFooter from "../components/SiteFooter.vue";

import { useDataStore } from "@/stores/data.js";
import { ref } from "vue";

export default {
  components: { SiteNav, SiteFooter },
  setup() {
    const { mutate } = useDataStore();
    const loading = ref(false);
    async function countVisit(url) {
      loading.value = true;
      try {
        await mutate({
          endpoint: "SaveURLVisit",
          data: { input: { url } },
          service: "SETTINGS",
        });
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    }
    return { countVisit, loading };
  },

  methods: {
    //     beaconCode(url) {
    //       const graphqlEndpoint =
    //         "https://settingsservice-9513d436f9a6e2cc.onporter.run/graphql";

    //       // Your GraphQL query or mutation
    //       const graphqlQuery = `mutation SaveURLVisit($input: URLVisitInput!) {
    //   SaveURLVisit(input: $input) {
    //     success
    //     message
    //     returnStatus
    //     token
    //     data
    //     privileges
    //   }
    // }
    // `;
    //       const inputParameters = {
    //         url,
    //       };

    //       const graphqlRequest = {
    //         operationName: "SaveURLVisit",
    //         query: graphqlQuery,
    //         variables: { input: inputParameters },
    //       };

    //       const beaconSent = navigator.sendBeacon(
    //         graphqlEndpoint,
    //         JSON.stringify(graphqlRequest)
    //       );
    //       // Convert the query to FormData and send it using sendBeacon
    //       // const formData = new FormData();

    //       // formData.append("query", graphqlQuery);
    //       // formData.append("variables", JSON.stringify({ input: inputParameters }));

    //       // Send the data using sendBeacon
    //       // const beaconSent = navigator.sendBeacon(graphqlEndpoint, formData);

    //       if (beaconSent) {
    //         console.log("Beacon sent successfully");
    //       } else {
    //         console.error("Beacon not sent");
    //       }
    //     },
    async openAppStore() {
      // Detect the user's platform (iOS or Android)
      // const isIOS =
      //   (/iPad|iPhone|iPod/.test(navigator.userAgent) ||
      //     navigator.userAgent.indexOf("Apple") != -1) &&
      //   !window.MSStream;

      const isIOS =
        navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/Mac/i);
      const ua = navigator.userAgent.toLowerCase();
      const isAndroid = ua.includes("android");

      // Define the URLs for the App Store and Play Store
      const appStoreUrl = "https://apps.apple.com/us/app/eazipay-business/id6446281426";
      const playStoreUrl = "https://play.google.com/store/apps/details?id=com.myeazipay.business";

      // Open the respective store based on the user's platform
      if (isIOS) {
        await this.countVisit(appStoreUrl);
        window.location.href = appStoreUrl;
      } else if (isAndroid) {
        await this.countVisit(playStoreUrl);
        window.location.href = playStoreUrl;
      }
      // else {
      //   // Handle other platforms if needed
      //   console.warn("Unsupported platform");
      // }
    },
  },
  async mounted() {
    await this.openAppStore();
  },
};
</script>
